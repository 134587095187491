import React from 'react';
import Block from '../LongScroll/Block';
import Store from '../Store/Store';




const Blocks = props => {

	/*
    let user = Store.get( 'user' )
    if ( user === null || user === undefined ) {
      props.history.replace( '/login/' )
    }
    */

	let pages = props.pages

	//console.log( 'Blocks pages', pages )

	if ( props.pages && props.pages.length > 0  && props.pages[ 0 ].template === 'hero-tabs' ) {
	    pages = pages.filter( a => a.template === 'hero-tabs' )
	}

	return (
		pages.map( ( a, i ) => {
			return (
				<Block key={ a.id + '-' + i } history={ props.history } page={ a } newslimit={ props.newslimit } section={ props.section } slug={ props.slug } sub={ props.sub } limit={ props.newslimit } />
			)
		} ) 
	)
	
}

export default Blocks
