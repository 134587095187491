import React, { Fragment, useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import ReactGA from 'react-ga';
import Store from '../Store/Store';
import Login from '../Login/Login';
import LongScroll from '../LongScroll/LongScroll';
import BlogPage from '../Blogs/BlogPage';
import NewsPage from '../News/NewsPage';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Cookies from '../Cookies/Cookies';
import Background from '../Fiber/Background';
import '../css/index.css';

const App = ( props ) => {

  const [ tmp, setTmp ] = useState( 0 );
  const [ offset, setOffset ] = useState(0);
  const [ user, setUser ] = useState( null );
  const [ lang, setLang ] = useState( Store.get( 'lang' ) )

  useEffect(() => {
    window.onscroll = () => {
      setOffset( window.pageYOffset )
    }
  }, []);

  ReactGA.initialize('GTM-K6V5BGW');

    /*
    <Router basename={ process.env.PUBLIC_URL }>
      <Routes>
        <Route path="/*" element={<Main />} />
        <Route path="/" element={<Navigate replace to="/sign-in" />} />
      </Routes>
    </Router>
    */

  return (
    <Fragment>
      <Router basename={ process.env.PUBLIC_URL }>
        <Fragment>
        <Routes>

            <Route path="/funds" element={<LongScroll section="funds" />} />
            <Route path="/funds/:slug" element={<LongScroll section="funds" />} />
            <Route path="/funds/:slug/:sub" element={<LongScroll section="funds" />} />

            <Route path="/asset-management" element={<LongScroll section="asset-management" />} />
            <Route path="/asset-management/:slug" element={<LongScroll section="asset-management" />} />
            <Route path="/asset-management/:slug/:sub" element={<LongScroll section="asset-management" />} />

            <Route path="/wealth-structuring" element={<LongScroll section="wealth-structuring" />} />
            <Route path="/wealth-structuring/:slug" element={<LongScroll section="wealth-structuring" />} />
            <Route path="/wealth-structuring/:slug/:sub" element={<LongScroll section="wealth-structuring" />} />

            <Route path="/services" element={<LongScroll section="services" />} />
            <Route path="/services/:slug" element={<LongScroll section="services" />} />
            <Route path="/services/:slug/:sub" element={<LongScroll section="services" />} />

            <Route path="/contact" element={<LongScroll section="contact" />} />
            <Route path="/contact/:slug" element={<LongScroll section="contact" />} />
            <Route path="/contact/:slug/:sub" element={<LongScroll section="contact" />} />

            {/*

            <Route exact path="/funds/" render={ routeProps => <LongScroll { ...routeProps } section="funds" />} />
            <Route exact path="/funds/:slug/" render={ routeProps => <LongScroll { ...routeProps } section="funds" />} />
            <Route exact path="/funds/:slug/:sub/" render={ routeProps => <LongScroll { ...routeProps } section="funds" />} />

            <Route exact path="/asset-management/" render={ routeProps => <LongScroll { ...routeProps } newslimit="200" section="asset-management" />} />
            <Route exact path="/asset-management/:slug/" render={ routeProps => <LongScroll { ...routeProps } section="asset-management" />} />
            <Route exact path="/asset-management/:slug/:sub/" render={ routeProps => <LongScroll { ...routeProps } section="asset-management" />} />

            <Route exact path="/wealth-structuring/" render={ routeProps => <LongScroll { ...routeProps } section="wealth-structuring" />} />
            <Route exact path="/wealth-structuring/:slug/" render={ routeProps => <LongScroll { ...routeProps } section="wealth-structuring" />} />
            <Route exact path="/wealth-structuring/:slug/:sub/" render={ routeProps => <LongScroll { ...routeProps } section="wealth-structuring" />} />

            <Route exact path="/services/" render={ routeProps => <LongScroll { ...routeProps } section="services" />} />
            <Route exact path="/services/:slug/" render={ routeProps => <LongScroll { ...routeProps } section="services" />} />
            <Route exact path="/services/:slug/:sub/" render={ routeProps => <LongScroll { ...routeProps } section="services" />} />

            <Route exact path="/contact/" render={ routeProps => <LongScroll { ...routeProps } section="contact" />} />
            <Route exact path="/contact/:slug/" render={ routeProps => <LongScroll { ...routeProps } section="contact" />} />
            <Route exact path="/contact/:slug/:sub/" render={ routeProps => <LongScroll { ...routeProps } section="contact" />} />

            */}


            {/*
            <Route exact path="/blogs/:slug/" component={ BlogPage }/>
            <Redirect from="*" to="/services" />
            */}

            <Route path="/" element={<Navigate replace to="/services" />} />

        </Routes>

        <Fragment>
          <Footer lang={ lang } />
          <Header lang={ lang } setLang={ setLang } offset={ offset } />
          {/*
          <Route path="/:route/" render={ routeProps => <Header { ...routeProps } lang={ lang } setLang={ setLang } offset={ offset } />} />
          <Route exact path="/" render={ routeProps => <Header { ...routeProps } lang={ lang } setLang={ setLang } offset={ offset } />} />
          */}
          <Cookies/>
        </Fragment>

        </Fragment>
      </Router>
    </Fragment>
  ) 
  }
//}

export default App 
