import React, { Fragment } from 'react';
import Store from '../Store/Store';
import { Link, Router } from 'react-router-dom'; 
import parseHtml from '../parseHtml/parseHtml';
import styled from 'styled-components'
import icons from '../icons/icons';
import Icon from '../Icon/Icon';
import parse from 'html-react-parser';
//import Register from '../Register/Register';

import bgImageWhite from '../img/logo-white.png';
let bgUrl = `${ bgImageWhite }`

const FooterDiv = styled.div`
  p { font-size: 0.8rem; line-height: 1.4rem; margin:0 0; font-weight:300; }
  a { text-decoration:none; color:white; display:inline-block; padding-bottom:0; }
  a:hover { color: rgb(32, 190, 225); }
  @media only screen and ( min-width: 801px) {
  }
`;

const FooterIcon = props => (
  <div style={{ 
    display:'inline-block', 
    background:'white', 
    fill:'#263238', 
    width:'32px', 
    height:'32px', 
    padding:'0px',
    borderRadius:'16px', 
    margin:'10px' 
  }} >
  <a href={ props.to } target="_blank" > 
    <Icon icon={ props.icon } style={{ padding:'6px', width:'20px', height:'20px' }} />
  </a>
  </div>
)

const Footer = props => {
  let pagesObject = Store.get( 'pagesObject' ) || {}
  let footerArray = pagesObject.footer

  return (
  <div className="footer-wrapper" style={{ width:'100%', overflowX:'hidden' }} >


    <div className="footer" style={{ marginTop:'30px', maxWidth:'900px' }} >

    {/*
    <Register structure={{ color:'white', backgroundColor:'rgba(0,0,0,0)' }} />
    */}

      <div style={{ display:'inline-block' }} >
        <div className="footer-flex-box" >
          { footerArray.map( ( a, i ) => {
            let s = JSON.parse( a.structure )
            let copyHtml = parseHtml( props.lang === 'fi' ? s.columnFi : s.column || '' )
            return ( 
              <div key={ a.id } className='footer-item-group' >
                <h5 style={{ marginTop:0, textTransform:'uppercase' }} >{ props.lang === 'fi' ? a.titleFi : a.title }</h5>
                <FooterDiv >
                  { copyHtml }
                </FooterDiv>
              </div>
            )
          } ) }
        </div>

        <div style={{ textAlign:'left', margin:'50px 0 0 20px' }} >
          <div style={{ display:'inline-block', fontSize:'0.8rem', verticalAlign:'top', padding:'15px 20px 15px 10px' }} >Afalon investment Management Oy &copy; 2021</div>
          <div style={{ display:'inline-block', verticalAlign:'top', padding:'0 20px 15px 10px' }} >
            <FooterIcon icon="linkedin" to="https://www.linkedin.com/" />
            {/*
            <FooterIcon icon="facebook" to="https://www.facebook.com//" />
            <FooterIcon icon="twitter" to="https://twitter.com/" />
            <FooterIcon icon="instagram" to="https://www.instagram.com/" />
            */}
          </div>
        </div>

      </div>

    </div>

  </div>

  )
}

export default Footer 
