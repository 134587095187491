import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';



import Request from '../Request/Request';
import Store from '../Store/Store';
import logoImage from '../img/dedalos-logo-black.png';
const logoUrl = `${ logoImage }`

const Login = props => {

  const navigate = useNavigate()
  //let history = useHistory();

  //const [ tmp, setTmp ] = useState( 0 );

  const home = () => {
    //history.replace( '/' )
    navigate( '/' )
  }

  const submitSignIn = e => {
    e.stopPropagation()
    e.preventDefault();
    let user = document.getElementById( 'userid' ).value
    let password = document.getElementById( 'password' ).value
    Request.post( 
      "signin", 
      { email:user, password:password },
      result => { 
        console.log( 'signin success' )
        props.setUser( result.data )
        Store.set( 'user', result.data )
        home()
      },
      error => { 
        console.log( 'error', error )
      } 
    )
  }

  return (
      <div style={{ position:'fixed', left:0, top:0, width:'100%', height:'100%', backgroundColor:'#345', color:'white', boxSizing:'border-box' }} >
        <div style={{ position:'fixed', left:'50%', top:'50%', textAlign:'left', padding:'10px 20px 20px 20px', transform:'translate(-50%, -50%)', width:'240px', height:'260px', backgroundColor:'white', color:'black', borderRadius:'4px', boxShadow:'0 5px 10px rgba(0,0,0,0.5)', boxSizing:'border-box' }} >

          <div style={{ display:'inline-block', width:'100%', textAlign:'center', fontWeight:'700', fontSize:'14px', padding:'10px 0 5px 0' }} >
            AFALON
          </div>

          <form onSubmit={ submitSignIn } >
            <label htmlFor="userid" style={{ fontSize:'11px' }} ><b>User</b></label>
            <br/>
            <input id="userid" name="userid" className="form-input" style={{ width:'176px', margin:'0 0 10px 0' }} />
            <br/>
            <label htmlFor="password" style={{ fontSize:'11px' }} ><b>Password</b></label>
            <br/>
            <input id="password" name="password" type="password" className="form-input" style={{ width:'176px', margin:'0 0 0 0' }}  />
            <br/>
            <input type="submit" className="open-account-button" value="Sign In" />
          </form>
        </div>
      </div>
    )
}


export default Login
