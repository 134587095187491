/*
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from 'react';
import ReactDOM from 'react-dom';
//import { BrowserRouter as Router } from 'react-router-dom'
import Data from './Data/Data-dynamic';
import App from './App/App'
import Store from './Store/Store'

Store.init( () => {  
	const lang = Store.get( 'lang' ) || navigator.language || navigator.userLanguage
	Store.set( 'lang', lang === 'fi' ? 'fi' : 'en' )
	Data().fetch( ( data ) => {
		//console.log( 'index.js data fetched', data )
		ReactDOM.render((
		    <App/>
		), document.getElementById( "root" ) )

	} )
} )
*/

import React from 'react';
import { createRoot } from 'react-dom/client';
import './css/index.css';
import Data from './Data/Data-dynamic';
import App from './App/App';
import Store from './Store/Store';

const container = document.getElementById('root');
const root = createRoot( container );

Store.clear()

Store.init( () => {

	const lang = Store.get( 'lang' ) || navigator.language || navigator.userLanguage
	Store.set( 'lang', lang === 'fi' ? 'fi' : 'en' )
	Data().fetch( ( data ) => {
		root.render( <App /> )
	} )


})
