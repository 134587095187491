import React from 'react'

const ActionButton = props => {
  const baseStyle={
    position:'relative',
    opacity:'100%', userSelect:'none', 
    margin:'0', 
    padding:'10px 20px 10px 20px', 
    fontFamily:'Montserrat', fontSize:'0.75rem', fontWeight:'700', 
    borderRadius:'21px', 
    verticalAlign:'middle',
    textAlign:'center',
    textTransform:'uppercase', 
    background:'linear-gradient(180deg, #FFAA4C 0%, #FF8E25 100%)', 
    color:'white',
    overflow: 'hidden',
    boxSizing:'border-box',
  }
  let useStyle={ ...baseStyle, ...props.style }
  return(
    <div style={ useStyle } >
      <div style={{ margin:0, lineHeight:'12px' }}>
        { props.title }
      </div>
    </div>
  )
}

export default ActionButton
