import React, { useState, useEffect, useRef, Fragment } from 'react'
import { useLocation, useNavigate, Link, useParams } from 'react-router-dom';
import parseHtml from '../parseHtml/parseHtml';
import styled from 'styled-components'
import bgImage from '../img/dedalos-logo-black.png';
import bgImageWhite from '../img/dedalos-logo-white.png';
import ActionButton from '../ActionButton/ActionButton';
import Store from '../Store/Store'

//import '../css/index.css';

const ShowOnlyOnDesktop = styled.div`
  display: none;
  @media only screen and ( min-width: 1037px) {
    display: block;
  }
`;

const ShowOnlyOnMobile = styled.div`
  display: none;
  @media only screen and ( max-width: 1036px) {
    display: block;
  }
`;

const DesktopMemuItem = props => (
  <div className={ props.route === props.handle ? 'header-underline-active' : 'header-underline' } >
    <Link draggable="false" to={"/" + props.handle + "/"} style={{ color:props.color, textTransform:'uppercase' }} >
      { parseHtml( props.lang === 'fi' ? props.titleFi : props.titleEn ) }
    </Link>
  </div>
)

const SectionsDesktop = props => {

  let color = props.top ? 'white' : 'black' 
  let underLine = props.top ? '32,190,225' : '32,190,225' 
  let allSections = Store.get( 'sections' ) || []
  let lang = Store.get( 'lang' ) || []

  return (
    <div style={{ display:'flex', userSelect:'none', margin:'16px 0 0 0' }} >
      { allSections.map( s => {
        return(
          <DesktopMemuItem key={ s.id } route={ props.route } handle={ s.handle } lang={ lang } color={ color } titleFi={ s.title } titleEn={ s.title_en }  />
        )
      } ) }
    </div>
  )  
}

const MobileMemuItem = props => (
    <Link draggable="false" to={"/" + props.handle + "/"} style={{ textTransform:'uppercase' }} >
      <div style={{ lineHeight:'16px', padding:'7px 0' }} >
        { parseHtml( props.lang === 'fi' ? props.titleFi : props.titleEn ) }
      </div>
    </Link>
)

const SectionsMobile = props => {
  let allSections = Store.get( 'sections' ) || []
  let lang = Store.get( 'lang' ) || []
  return(
    <div>
      { allSections.map( s => {
        return(
          <MobileMemuItem key={ s.id } route={ props.route } handle={ s.handle } lang={ lang } titleFi={ s.title } titleEn={ s.title_en }  />
        )
      } ) }
    </div>
  )
}

const SignInSignUp = props => {

  //const [ lang, setLang ] = useState( Store.get( 'lang' ) )

  const toggleLang = () => {
    let newLang = props.lang === 'fi' ? 'en' : 'fi' 
    props.setLang( newLang )
    Store.set( 'lang', newLang )
  }

  return (
    <div onClick={ toggleLang } style={{ display:'flex', whiteSpace:'nowrap', transform:'translateY(-10px)' }} >

      <div className="header-underline" style={{ height:'0.9rem', marginTop:'10px', marginRight:'0', opacity:props.lang === 'fi' ? '1.0' : '0.75', fontSize:'0.75rem', color:props.color }} >
          FI
      </div>
      <div className="header-underline" style={{ height:'0.9rem', marginTop:'10px', marginRight:'0', opacity:'0.5', fontSize:'0.75rem', color:props.color }} >
          |
      </div>
      <div className="header-underline" style={{ height:'0.9rem', marginTop:'10px', marginRight:'0', opacity:props.lang === 'en' ? '1.0' : '0.75', fontSize:'0.75rem', color:props.color }} >
          EN
      </div>

    </div>
  )
}

const Header = props => {

  //let route = props && props.match && props.match.params ? props.match.params.route : null
  const path = useLocation().pathname
  const pathArray = path.split( '/' )
  const route = pathArray[ 1 ]
  //console.log( pathname )

  const [ menu, setMenu ] = useState( false )

  useEffect( () => {
  }, [] )

  let top = props.offset < 100 
  let color = top ? 'white' : 'black' 
  //let underLine = top ? '32,190,225' : '32,190,225' 
  let bgUrl = top ? `${ bgImageWhite }` : `${ bgImage }`

  //

  return (
    <Fragment>
    <div style={{ position:'fixed', left:0, top:0, width:'100%', height:'60px', boxSizing:'border-box' }} >

      <div style={{ position:'fixed', left:0, top:0, width:'100%', height:'60px', boxSizing:'border-box', boxShadow:!top && '0 2px 16px rgba(0,0,0,0.25)', background:!top ? 'white' : 'none' }} />
        <div className="products" style={{ position:'relative', width:'100%', maxWidth:'900px', margin:'auto', textAlign:'left', fontSize:'0.75rem', lineHeight:'14px', fontWeight:'700', whiteSpace:'nowrap', userSelect:'none' }} >

          {/*
          <div style={{ position:'absolute', top:0, left:0, margin:'24px 0 0 30px', fontSize:'18px', textAlign:'center' }} >
            <Link draggable="false" to="/services/" style={{ color:color, padding:'5px 12px 5px 12px', border:'1px solid ' + color }} >
              AFALON
            </Link>
          </div>
          */}

          <div style={{ position:'absolute', top:0, left:0, margin:'12px 0 0 30px', fontSize:'18px', textAlign:'center' }} >
            <Link draggable="false" to="/services/" style={{ padding:0 }} >
              <img src={ process.env.PUBLIC_URL + '/img/logo-white-bg.svg' } style={{ height:40 }} />
            </Link>
          </div>

          <ShowOnlyOnDesktop style={{ position:'absolute', left:'50%', top:0, transform:'translateX(-50%)', margin:'0 100px 0 0' }} >
            <SectionsDesktop top={ top } route={ route } />
          </ShowOnlyOnDesktop>

          <ShowOnlyOnDesktop style={{ position:'absolute', top:0, right:0, whiteSpace:'nowrap', margin:'22px 30px 0 0' }} >
            <SignInSignUp top={ top } color={ color } lang={ props.lang } setLang={ props.setLang }  />
          </ShowOnlyOnDesktop>

        <ShowOnlyOnMobile>
        { menu 
          ? <div 
              style={{ position:'fixed', cursor:'pointer', left:0, top:0, width:'100%', height:'100%', textAlign:'right', color:'black', backgroundColor:'rgba(0,0,0,0.25)' }} 
              onClick={ () => setMenu( false ) }
            >
              <div style={{ position:'absolute', right:'-5px', top:'-5px', margin:0, width:'220px', height:'380px', borderRadius:'8px', background:'white', color:'#000', boxShadow:'0 0 8px 1px rgba(0,0,0,0.25)',  }} >

                <div style={{ opacity:'100%', userSelect:'none', position:'absolute', right:'30px', top:'25px' }} >
                  <span className="material-icons">close</span>
                </div>

                <div 
                  style={{ opacity:'100%', cursor:'pointer', userSelect:'none', position:'absolute', right:'34px', top:'60px', fontSize:'0.8rem', lineHeight:'40px', fontWeight:'700' }} 
                >
                  <div style={{ padding:'5px' }} />
                  <SectionsMobile />
                  <div style={{ padding:'8px' }} />
                  <div style={{ display:'inline-block', transform:'translateX(16px)', width:'80px', maxWidth:'80px' }} >
                    <SignInSignUp top={ top } color={ '#000' } lang={ props.lang } setLang={ props.setLang }  />
                  </div>
                </div>

              </div>

            </div>

          : <Fragment>
              <div 
                style={{ opacity:'100%', cursor:'pointer', userSelect:'none', position:'absolute', color:color, right:'25px', top:'20px' }} 
                onClick={ () => setMenu( true ) }
              >
                <span className="material-icons">menu</span>
              </div>
            </Fragment>
        }
        </ShowOnlyOnMobile>


      </div>
    </div>
    </Fragment>
  )  
}

export default Header
