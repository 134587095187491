import objectPath from "object-path";
import localforage from "localforage";

var Store = function() {
	localforage.config({
	    name: 'afalon',
	});
	return {

		data: {},

		init: function( callBack ) {
			localforage.iterate( function( value, key, iterationNumber ) { objectPath.set( Store.data, key, value ) } )
			.then( callBack )
			.catch( function( err ) { console.error( 'Store.init.localforage.error', err) } );
		},

		clear: function() {
			localforage.clear()
			.then( function() { console.log('Database is empty.') } ); // debug clear localForage
		},

		set: function( inKey, inData ) {
			//console.log( 'Store.set', inKey, inData )
			objectPath.set( Store.data, inKey, JSON.parse(JSON.stringify( inData )) );
			localforage.setItem( inKey, inData ).then( function ( value ) {
			}).catch( function( err ) {
			    console.error( 'Store.set.localforage.setItem.error', err, inKey );
			});

		},

		get: function( inKey ) {
			let key = inKey
			if ( key.slice( -1 ) === '.' ) {
				key = key.slice( 0, -1 ); // if '.' last char, remove it
			}
			return objectPath.get( Store.data, key ); // returns reference to object, key can also be an array
		},

	} 
}();

export default Store

