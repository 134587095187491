import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';

import Store from '../Store/Store';
import Block from '../LongScroll/Block';
import parse from 'html-react-parser';
import styled from 'styled-components'
import Background from '../Fiber/Background';

const ContainerDiv = styled.div`
  text-align:center; 
  width:100%; 
  max-width:260px;
  margin:0 auto;
  @media only screen and ( min-width: 961px) {
    max-width:100%;
  }
`;

const TabDiv = styled.div`
  display:table; 
  margin:4px auto;
  font-size:0.9rem;
  white-space:normal; padding:2px 0 0 0;
  @media only screen and ( min-width: 961px) {
    display:inline-block; 
    margin:8px;
    font-size:0.8rem; 
  }
`;

const Tabs = props => {
  let prevSlug = null
  const lang = Store.get( 'lang' )
  const cookies = Store.get( 'cookies' )

  //console.log( 'HeroTabs Tabs cookies', cookies )
  if ( cookies === 'all' && window.pageYOffset === 0 ) {
    //console.log( 'HeroTabs Tabs cookies all report' )
    ReactGA.pageview( window.location.pathname + window.location.search );
  }

  return(
  <div style={{ 
    position:'relative', width:'100%', 
    padding:'2px', 
    textAlign:'center', 
    color:'black', fontWeight:'700', 
    whiteSpace:'normal', boxSizing:'border-box',  
  }} >
    <ContainerDiv>
      { props.pages.map( ( a, i ) => {
        //console.log( 'a', a )
        let structure = JSON.parse( a.structure )
        if ( prevSlug === a.slug ) { 
          return <Fragment key={ a.id } /> 
        } else {
          prevSlug = a.slug
          return (
            <Link key={ a.id }  to={ '/' + props.section + '/' + a.slug } draggable="false" >
            <TabDiv 
              key={ a.id } 
              style={{ 
                color:props.index === i ? 'black' : '#999', 
                borderBottom:props.index === i ? '4px solid rgb(32,190,225)' : '4px solid rgba(0,0,0,0)', 
              }}
            > 
              { parse( ( lang === 'fi' ? structure.titleFi : a.title ) || a.title ) }
            </TabDiv>
            </Link>
          ) 
        }
      } ) }
    </ContainerDiv>
  </div>
  )  
}

const HeroTabs = props => {
  let allPages = Store.get( 'pages' ) || []
  // get and filter pages for tabs
  let pages = allPages.filter( a => a.section === props.structure.tabsSection )
  pages = pages.filter( a => a.template !== 'hero' )
  pages = pages.filter( a => a.template !== 'hero-tabs' )

  // get tabIndex
  let tabIndex = 0
  let prevSlug = null
  let subContent = []
  if ( props.slug ) {
    pages.forEach( ( a, i ) => {
      if ( a.slug === props.slug ) {
        if ( prevSlug !== a.slug ) {
          tabIndex = i
        }
        subContent.push( a )
      }
      prevSlug = a.slug
    } )
  }
  // get current page
  let page = {}
  if ( pages && pages.length > 0 && pages[ tabIndex ] && pages[ tabIndex ].hasOwnProperty( 'structure' ) ) {
    page = pages[ tabIndex ]
  }


  // nack from sub-page
  let showBackButton = false
  let mainTitle = page.title
  let linkBackToMain = '/' + props.section + '/' + page.slug

  // get and filter subPages, and replace page  
  if ( props.sub && props.slug && props.structure.subSection && props.structure.subSectionSlug && props.slug === props.structure.subSectionSlug ) {
    let subPages = allPages.filter( a => a.section === props.structure.subSection )
    if ( props.slug ) {
      subPages.forEach( ( a, i ) => {
        if ( a.slug === props.sub ) {
          page = a
          showBackButton = true
        }
      } )
    }
  }

  //console.log( 'subContent', subContent )

  useEffect( () => {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }, [ page ] )

  const lang = Store.get( 'lang' )

  //const cookies = Store.get( 'cookies' )
  //console.log( 'HeroTabs cookies', cookies )

  return (
    <Fragment>
      <div id="frontpage" className="front-wrapper" style={{ height:'28vh', minHeight:'300px',  }} >
        {/*
        <img style={{ position:'absolute', top:0, left:0, width:'100%', height:'28vh', minHeight:'300px', objectFit:'cover', objectPosition:'50% 0', opacity:1 }} alt="" src={ props.files[ 0 ] } />
        */}
        <Background/>
        <div style={{ position:'absolute', width:'100%', maxWidth:'1240px', height:'28vh', minHeight:'300px', top:0, left:'50%', transform:'translateX(-50%)', boxSizing:'border-box' }} >
          <div className="front" style={{ 
              userSelect:'none', display:props.structure.showTitle === 'on' ? 'block' : 'none', 
              textAlign:props.structure.titlePosition, 
              color:props.structure.titleColor, 
              fontFamily:'Montserrat', 
              fontSize:'2.5rem', lineHeight:'2.5rem', fontWeight:'700', letterSpacing:'.5px', 
              padding:'20px 0 0 0', textShadow:'0 2px 4px rgba(0,0,0,1)', 
            }}>
              { lang === 'fi' ? props.structure.titleFi : props.structure.titleEn }
          </div>
          { props.back && 
          <div 
            onClick={ () => { props.history.goBack() } } 
            style={{ display:'block', cursor:'pointer', position:'absolute', left:'30px', bottom:'30px', height:'30px', color:'white', padding:'4px 8px', borderRadius:'20.5px', fontSize:'1rem', background:'rgba(0,0,0,0.15)', textShadow:'0 1px 2px rgba(0,0,0,0.5)' }} 
          >
            <span className="material-icons" style={{ transform:'translateY(3px)' }} >arrow_back</span>
            <div style={{ display:'inline-block', fontWeight:'bold', transform:'translateY(-3px)', padding:'0 10px' }} >Back</div>
          </div>
          }
        </div>
      </div>
      <div style={{ position:'relative', margin:'5px 0 0 0', textAlign:'center', boxSizing:'border-box' }} >
        <Tabs section={ props.section } pages={ pages } index={ tabIndex } />
      </div>

      {/*
      <Block key={ page.id } page={ page } section={ props.structure.section } />
      */}

      { subContent.length > 1 
        ? subContent.map( ( a, i ) => {
            return (
              <Block key={ a.id + '-' + i } page={ a } section={ props.structure.section } />
            )
          } ) 
        : <Block key={ page.id } page={ page } section={ props.structure.section } />
      }

    </Fragment>
  )
}

export default HeroTabs
