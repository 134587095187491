import React, { Fragment } from 'react';
import parseHtml from '../parseHtml/parseHtml';

const TwoColumns = props => {
  return (
  <div className="products-wrapper" style={{ backgroundColor:props.structure.backgroundColor, color:props.structure.color, boxSizing:'border-box', padding:'40px 0 10px 0' }} >
    <div className="products"  >
      <h1 style={{ padding:'0px 30px 20px 30px', margin:'0', boxSizing:'border-box' }}>{ props.page.title }</h1>
      { props.structure.column1Image 
        ? <div className="two" style={{ padding:'20px 30px', margin:'0', backgroundColor:props.structure.column1BackgroundColor, boxSizing:'border-box' }}>
            <img style={{ objectFit:'cover', width:'100%', opacity:1 }} alt="image" src={ props.page.dir + props.structure.column1Image } />
          </div>
        : <div className="two" style={{ padding:'0 30px', margin:'0', backgroundColor:props.structure.column1BackgroundColor, boxSizing:'border-box' }}>
            { parseHtml( props.structure.column1 || '' ) }
          </div>
      }
      { props.structure.column2Image 
        ? <div className="two" style={{ padding:'20px 30px', margin:'0', backgroundColor:props.structure.column2BackgroundColor, boxSizing:'border-box' }}>
            <img style={{ objectFit:'cover', width:'100%', opacity:1 }} alt="image" src={ props.page.dir + props.structure.column2Image } />
          </div>
        : <div className="two" style={{ padding:'0 30px', margin:'0', backgroundColor:props.structure.column2BackgroundColor, boxSizing:'border-box' }}>
            { parseHtml( props.structure.column2 || '' ) }
          </div>
      }
    </div>
  </div>
  )
}

export default TwoColumns
